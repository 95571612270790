import { LysaCountry } from "@lysaab/countries";
import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import {
  ContactEmailAddresses,
  ContactPhoneNumbers,
} from "../../../../../../../components/Contact";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../../context/LocalizationContext";
import { KF_WITHDRAWAL_STATUS } from "../../../../../../../data/dataDanica";
import { KfMigrationContext } from "../KfMigrationContext";

interface Props {
  retry: () => void;
}

export const Error: React.FunctionComponent<Props> = ({ retry }) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const { state: kfWithdrawalState } = useContext(KfMigrationContext);

  if (kfWithdrawalState.signingStatus?.status !== KF_WITHDRAWAL_STATUS.ERROR) {
    return null;
  }

  return (
    <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
      <div>
        <p>
          <TranslatedText
            id="withdrawalRequest.kf.signing.error.paragraph"
            values={{
              phone: () => {
                const phoneNumber = intl.formatMessage(
                  ContactPhoneNumbers[
                    localizationContext.state.country || LysaCountry.SWEDEN
                  ]
                );
                return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
              },
              email: (...chunks: string[]) => {
                const emailAddress = intl.formatMessage(
                  ContactEmailAddresses[
                    localizationContext.state.country || LysaCountry.SWEDEN
                  ]
                );
                return <a href={`mailto:${emailAddress}`}>{emailAddress}</a>;
              },
            }}
          />
        </p>
        <Button
          size="small"
          block
          onClick={retry}
          label={
            <TranslatedText id="withdrawalRequest.kf.signing.error.button" />
          }
        />
      </div>
    </Snackbar>
  );
};
