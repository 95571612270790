import {
  LysaFormRef,
  Spinner,
  Form,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation } from "react-router";
import { LysaAccountSelectionCard } from "../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { TranslatedText } from "../../../../components/TranslatedText";
import {
  InvestmentAccount,
  AccountType,
  InvestmentAccountId,
  isSavingsAccount,
  SavingsAccountId,
  SavingsAccount,
} from "../../../../data/dataAccounts";
import {
  dataWithdrawals,
  WithdrawableSavingsAccount,
  WithdrawableSavingsAccountStatus,
  WithdrawalAccount,
} from "../../../../data/dataWithdrawals";
import { useAccounts } from "../../../../hooks/useAccounts";
import { Disclaimer } from "../components/disclaimer/Disclaimer";
import {
  MergedInvestmentAccount,
  MergedSavingsAccount,
  MergedWithdrawalAccount,
  WithdrawalContext,
} from "../WithdrawalContext";
import { WithdrawalHelp } from "../components/withdrawalHelp/WithdrawalHelp";
import HorizontalDivider from "../../../../components/horizontalDivider/HorizontalDivider";

interface Props {
  next: () => void;
  waitingAccountsComponent?: ReactNode;
}

export interface WithAccountId {
  accountId?: InvestmentAccountId | SavingsAccountId;
}

const messages = defineMessages({
  lysaAccountLabel: {
    id: "withdrawal.story.lysa-account-selection.lysa.label",
  },
  lysaAccountRequired: {
    id: "withdrawal.story.lysa-account-selection.lysa.required",
  },
});

export function SelectLysaAccount({ next, waitingAccountsComponent }: Props) {
  const { accounts: lysaAccounts } = useAccounts();
  const [withdrawalAccounts, setWithdrawalAccounts] = useState<{
    investmentAccounts: WithdrawalAccount[];
    savingsAccounts: WithdrawableSavingsAccount[];
  }>();
  const formRef = useRef<LysaFormRef>();
  const withdrawalContext = useContext(WithdrawalContext);
  const intl = useIntl();
  const loaded = useRef(false);
  const location = useLocation<WithAccountId>();
  const selectedLysaAccount =
    withdrawalContext.state.selectedLysaAccount ||
    withdrawalContext.state.selectedLysaSavingsAccount;

  const setWithdrawalState = withdrawalContext.setState;

  const allLysaAccounts = useMemo(() => {
    if (!lysaAccounts || !withdrawalAccounts) {
      return undefined;
    }
    return [
      ...lysaAccounts.investmentAccounts.filter(
        ({ type }) => type !== AccountType.DANICA_KF
      ),
      ...lysaAccounts.savingsAccounts,
    ];
  }, [lysaAccounts, withdrawalAccounts]);

  useEffect(() => {
    if (loaded.current || typeof lysaAccounts === "undefined") {
      return;
    }
    loaded.current = true;
    Promise.all([
      dataWithdrawals.getWithdrawalAccounts(),
      dataWithdrawals.getWithdrawableSavingsAccounts(),
    ]).then(([withdrawalAccounts, withdrawableSavingsAccounts]) => {
      setWithdrawalAccounts({
        investmentAccounts: withdrawalAccounts,
        savingsAccounts: withdrawableSavingsAccounts,
      });
      if (location.state?.accountId) {
        const investmentAccount = mergeInvestmentAccount(
          location.state.accountId as InvestmentAccountId,
          lysaAccounts.investmentAccounts,
          withdrawalAccounts
        );
        if (investmentAccount) {
          setWithdrawalState({
            selectedLysaAccount: investmentAccount,
          });
          return;
        }
        const savingsAccount = mergeSavingsAccount(
          location.state.accountId as SavingsAccountId,
          lysaAccounts.savingsAccounts,
          withdrawableSavingsAccounts
        );
        if (savingsAccount) {
          setWithdrawalState({
            selectedLysaSavingsAccount: savingsAccount,
          });
          return;
        }
      }
    });
  }, [location.state, lysaAccounts, setWithdrawalState]);

  if (!withdrawalAccounts || !lysaAccounts || !allLysaAccounts) {
    return <Spinner />;
  }

  return (
    <div className="select-lysa-account-wrapper">
      <h2>
        <TranslatedText id="withdrawal.story.lysa-account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            formRef.current?.isInvalid ||
            !selectedLysaAccount ||
            isDisabled(selectedLysaAccount)
          ) {
            return;
          }
          next();
        }}
      >
        <Snackbar type={SNACKBAR_TYPES.INFO} icon>
          {lysaAccounts.savingsAccounts.length > 0 ? (
            <TranslatedText id="withdrawal.story.lysa-account-selection.amount-disclaimer.savings-account" />
          ) : (
            <TranslatedText id="withdrawal.story.lysa-account-selection.amount-disclaimer" />
          )}
        </Snackbar>

        {waitingAccountsComponent}

        <LysaAccountSelectionCard
          accounts={allLysaAccounts}
          selectedAccount={allLysaAccounts.find(
            (account) => account.accountId === selectedLysaAccount?.accountId
          )}
          onChange={(account) => {
            if (isSavingsAccount(account)) {
              const selectedLysaSavingsAccount = mergeSavingsAccount(
                account.accountId,
                lysaAccounts.savingsAccounts,
                withdrawalAccounts.savingsAccounts
              );
              withdrawalContext.setState({
                selectedLysaSavingsAccount,
                selectedLysaAccount: undefined,
              });
            } else {
              const selectedLysaAccount = mergeInvestmentAccount(
                account.accountId,
                lysaAccounts.investmentAccounts,
                withdrawalAccounts.investmentAccounts
              );
              withdrawalContext.setState({
                selectedLysaSavingsAccount: undefined,
                selectedLysaAccount,
              });
            }
          }}
          legend={intl.formatMessage(messages.lysaAccountLabel)}
        />
        {!isOngoingWithdrawal(selectedLysaAccount) &&
          withdrawalContext.state.selectedLysaAccount?.pendingOrder && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="withdrawal.story.lysa-account-selection.pending-order" />
            </Snackbar>
          )}
        {isOngoingWithdrawal(selectedLysaAccount) && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="withdrawal.story.lysa-account-selection.pending-withdrawal" />
          </Snackbar>
        )}
        {!isMoneyOnAccount(selectedLysaAccount) && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="withdrawal.story.lysa-account-selection.empty" />
          </Snackbar>
        )}
        {hasPendingInternalTransfer(selectedLysaAccount) && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="withdrawal.story.lysa-account-selection.pending-internal-transfer" />
          </Snackbar>
        )}
        {hasOngoingPartnerRebalance(selectedLysaAccount) && (
          <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
            <TranslatedText id="withdrawal.story.lysa-account-selection.pending-rebalance" />
          </Snackbar>
        )}

        <Button
          type="submit"
          block
          label={
            <TranslatedText id="withdrawal.story.lysa-account-selection.button" />
          }
        />
      </Form>
      <HorizontalDivider />
      <WithdrawalHelp />
      <Disclaimer />
    </div>
  );
}

const mergeInvestmentAccount = (
  accountId: InvestmentAccountId,
  investmentAccounts: InvestmentAccount[],
  withdrawalAccounts: WithdrawalAccount[]
): MergedInvestmentAccount | undefined => {
  const investmentAccount = investmentAccounts.find(
    (account) => account.accountId === accountId
  );
  const withdrawalAccount = withdrawalAccounts.find(
    (account) => account.accountId === accountId
  );

  if (investmentAccount && withdrawalAccount) {
    return { ...investmentAccount, ...withdrawalAccount };
  }

  return undefined;
};

const mergeSavingsAccount = (
  accountId: SavingsAccountId,
  investmentAccounts: SavingsAccount[],
  withdrawalAccounts: WithdrawableSavingsAccount[]
): MergedSavingsAccount | undefined => {
  const savingsAccount = investmentAccounts.find(
    (account) => account.accountId === accountId
  );
  const withdrawalAccount = withdrawalAccounts.find(
    (account) => account.accountId === accountId
  );

  if (savingsAccount && withdrawalAccount) {
    return { ...savingsAccount, ...withdrawalAccount };
  }

  return undefined;
};

function isDisabled(account: MergedWithdrawalAccount) {
  return (
    !isMoneyOnAccount(account) ||
    isOngoingWithdrawal(account) ||
    hasPendingInternalTransfer(account) ||
    (isInvestmentAccount(account) && account.pendingOrder)
  );
}

function isOngoingWithdrawal(account?: MergedWithdrawalAccount) {
  if (typeof account === "undefined") {
    return false;
  }

  if (isInvestmentAccount(account)) {
    return account.pendingWithdrawal;
  }
  return account.status === WithdrawableSavingsAccountStatus.PENDING_WITHDRAWAL;
}

function hasPendingInternalTransfer(account?: MergedWithdrawalAccount) {
  if (typeof account === "undefined") {
    return false;
  }

  if (isInvestmentAccount(account)) {
    return false;
  }
  return (
    account.status ===
    WithdrawableSavingsAccountStatus.PENDING_INTERNAL_TRANSFER
  );
}

function hasOngoingPartnerRebalance(account?: MergedWithdrawalAccount) {
  if (typeof account === "undefined") {
    return false;
  }

  if (isInvestmentAccount(account)) {
    return false;
  }

  return (
    account.status === WithdrawableSavingsAccountStatus.PENDING_REBALANCING
  );
}

function isMoneyOnAccount(account?: MergedWithdrawalAccount) {
  if (typeof account === "undefined") {
    return true;
  }

  if (isInvestmentAccount(account)) {
    return account.worth > 0;
  }
  return account.totalBalance > 0;
}

const isInvestmentAccount = (
  account: MergedWithdrawalAccount
): account is MergedInvestmentAccount => {
  return typeof (account as MergedInvestmentAccount).type !== "undefined";
};
