import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { useContext, useEffect, useState, VoidFunctionComponent } from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";

import { KfMigrationContext } from "./KfMigrationContext";
import { WITHDRAWAL_PAGE_URL } from "../../../../../../pages/withdrawal/overview/WithdrawalPage";
import {
  dataDanica,
  WithdrawalKFAccount,
} from "../../../../../../data/dataDanica";

interface Props {
  navigateToFrom: () => void;
}

export const Ongoing: VoidFunctionComponent<Props> = ({ navigateToFrom }) => {
  const { state: kfWithdrawalState, setState: setKfWithdrawalState } =
    useContext(KfMigrationContext);
  const { signingStatus } = kfWithdrawalState;
  const [accounts, setAccounts] = useState<WithdrawalKFAccount[]>();

  useEffect(() => {
    dataDanica.getWithdrawalKFAccounts().then(setAccounts);
  }, []);

  if (!signingStatus || !accounts) {
    return null;
  }

  return (
    <div className="withdrawal-request-page-done">
      <h1>Flyttuppdrag mottaget</h1>
      <Snackbar type={SNACKBAR_TYPES.SUCCESS} icon>
        <div>
          Vi kommer återköpa din försäkring hos Futur pension och öppna en ny
          hos Lysa Life med samma investeringsfokus och målfördelning mellan
          aktier och räntor som företaget tidigare har valt.
        </div>
      </Snackbar>
      {accounts.filter((account) => !account.pendingMove).length > 0 && (
        <Button
          onClick={() => {
            setKfWithdrawalState({
              account: undefined,
              from: undefined,
              signingStatus: undefined,
              signResponse: undefined,
            });
            navigateToFrom();
          }}
          label={"Hantera en försäkring till"}
          block
        />
      )}
      <Button
        variant="secondary"
        component={Link}
        to={getNavLink(WITHDRAWAL_PAGE_URL)}
        label={<TranslatedText id="withdrawalRequest.kf.ongoing.back" />}
        block
      />
    </div>
  );
};
