import { Story } from "@lysaab/ui-2";
import React, { useEffect } from "react";
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  matchPath,
  generatePath,
} from "react-router";
import { InvestmentAccountId } from "../../../../../../data/dataAccounts";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { HideNav } from "../../../../../../pages/HideNav";
import { Location } from "history";
import { KfMigrationContextProvider } from "./KfMigrationContext";
import { Confirm } from "./Confirm";
import { Signers } from "./Signers";
import { Sign } from "./Sign";
import { Ongoing } from "./Ongoing";
import { defineMessages, useIntl } from "react-intl";
import { From } from "./From";
import "./MigrationKfRequestPage.scss";
import { dataDanica, MoveType } from "../../../../../../data/dataDanica";
import { WITHDRAWAL_PAGE_URL } from "../../../../../../pages/withdrawal/overview/WithdrawalPage";
import { Intro } from "./Intro";
import { useSafeNavigation } from "../../../../../../hooks/useSafeNavigation";

export interface QueryParams {
  from: InvestmentAccountId;
  to: string;
}

export const MIGRATION_REQUEST_KF_PAGE_URL = "/migration/kf/request";

const ROUTES = {
  INTO: `${MIGRATION_REQUEST_KF_PAGE_URL}/`,
  FROM: `${MIGRATION_REQUEST_KF_PAGE_URL}/from`,
  CONFIRM: `${MIGRATION_REQUEST_KF_PAGE_URL}/confirm`,
  SIGNERS: `${MIGRATION_REQUEST_KF_PAGE_URL}/signers/:signingId`,
  SIGN: `${MIGRATION_REQUEST_KF_PAGE_URL}/sign/:signingId/:userId`,
  ONGOING: `${MIGRATION_REQUEST_KF_PAGE_URL}/ongoing`,
};

function getCurrentRouteIndex(location: Location) {
  return Object.values(ROUTES).findIndex((path) => {
    const match = matchPath(location.pathname, {
      path: getNavLink(path),
      exact: true,
    });
    return match !== null;
  });
}

const messages = defineMessages({
  ariaProgressLabel: {
    id: "withdrawalRequest.kf.story.ariaProgressLabel",
  },
});

function MigrationKfRequestPageInner() {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const currentIndex = getCurrentRouteIndex(location);
  const storyLength = Object.values(ROUTES).length;
  const safeNavigation = useSafeNavigation();

  let signersIndex;
  Object.keys(ROUTES).forEach((key, idx) => {
    if (key === "SIGNERS") {
      signersIndex = idx;
    }
  });

  useEffect(() => {
    dataDanica.getIsWithdrawalsClosed().then((activeResponse) => {
      if (activeResponse.closed) {
        history.replace(getNavLink(WITHDRAWAL_PAGE_URL));
      }
    });
  }, [history]);

  return (
    <Story
      ariaLabelProgress={() =>
        intl.formatMessage(messages.ariaProgressLabel, {
          current: currentIndex + 1,
          total: storyLength,
        })
      }
      header={"Flytt av kapitalförsäkring"}
      onExit={() => {
        const match = matchPath<{ signingId: string; userId: string }>(
          location.pathname,
          {
            path: getNavLink(ROUTES.SIGN),
            exact: true,
          }
        );
        if (match) {
          history.replace(
            generatePath(getNavLink(ROUTES.SIGNERS), {
              signingId: match.params.signingId,
            })
          );
        } else {
          history.push(getNavLink(WITHDRAWAL_PAGE_URL));
        }
      }}
      showClose={true}
      showBack={currentIndex > 0 && currentIndex < (signersIndex || 1)}
      onBack={history.goBack}
      transitionKey={currentIndex.toString()}
      progress={(100 / Object.keys(ROUTES).length) * (currentIndex + 1)}
    >
      <Switch {...{ order: currentIndex }} location={location}>
        <Route path={getNavLink(ROUTES.INTO)} exact>
          <Intro
            next={() => {
              safeNavigation(getNavLink(ROUTES.FROM));
            }}
          />
        </Route>
        <Route path={getNavLink(ROUTES.FROM)}>
          <From
            next={() => {
              safeNavigation(getNavLink(ROUTES.CONFIRM));
            }}
          />
        </Route>

        <Route path={getNavLink(ROUTES.CONFIRM)}>
          <Confirm
            next={(signingId) => {
              safeNavigation(
                generatePath(getNavLink(ROUTES.SIGNERS), {
                  signingId: signingId,
                })
              );
            }}
            startLink={getNavLink(ROUTES.INTO)}
          />
        </Route>

        <Route path={getNavLink(ROUTES.SIGNERS)}>
          <Signers
            navigateToDone={() => safeNavigation(getNavLink(ROUTES.ONGOING))}
            navigateToOngoing={() => safeNavigation(getNavLink(ROUTES.ONGOING))}
            navigateToSign={(signingId: string, userId: number) => {
              safeNavigation(
                getNavLink(
                  generatePath(ROUTES.SIGN, {
                    signingId: signingId,
                    userId: userId,
                  })
                )
              );
            }}
            linkToFrom={getNavLink(ROUTES.FROM)}
          />
        </Route>

        <Route path={getNavLink(ROUTES.SIGN)}>
          <Sign
            navigateToSigning={(signingId: string) => {
              safeNavigation(
                getNavLink(
                  generatePath(ROUTES.SIGNERS, { signingId: signingId })
                )
              );
            }}
          />
        </Route>

        <Route path={getNavLink(ROUTES.ONGOING)}>
          <Ongoing
            navigateToFrom={() => safeNavigation(getNavLink(ROUTES.FROM))}
          />
        </Route>
      </Switch>
    </Story>
  );
}

export function MigrationKfRequestPage() {
  return (
    <div className="withdrawal-kf-request-page">
      <HideNav />
      <KfMigrationContextProvider moveType={MoveType.CASH}>
        <MigrationKfRequestPageInner />
      </KfMigrationContextProvider>
    </div>
  );
}
