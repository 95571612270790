import React, { FunctionComponent, useReducer } from "react";
import { InvestmentAccount } from "../../../../../../data/dataAccounts";
import {
  WithdrawalKFAccount,
  GetKfWithdrawalStatus,
  WithdrawalSignResponse,
  MoveType,
} from "../../../../../../data/dataDanica";

export enum WithdrawalType {
  AMOUNT,
  DRAIN,
}

/**
 * This is the state for all generic sweden data.
 */
export interface KfMigrationState {
  account?: InvestmentAccount;
  from?: WithdrawalKFAccount;
  signingStatus?: GetKfWithdrawalStatus;
  signResponse?: WithdrawalSignResponse;
  moveType: MoveType;
}

// All types for the context. This includes setter methods
export interface KfWithdrawalContextProps {
  state: KfMigrationState;
  setState: (newState: Partial<KfMigrationState>) => void;
}

// Create the context. (This will just create a template)
export const KfMigrationContext = React.createContext<KfWithdrawalContextProps>(
  {} as KfWithdrawalContextProps
);

function stateReducer(
  state: KfMigrationState,
  newState: Partial<KfMigrationState>
) {
  return { ...state, ...newState };
}

interface Props {
  moveType: MoveType;
}

export const KfMigrationContextProvider: FunctionComponent<Props> = ({
  moveType,
  children,
}) => {
  const [state, setState] = useReducer(stateReducer, {
    moveType: moveType,
  });

  return (
    <KfMigrationContext.Provider value={{ state, setState }}>
      {children}
    </KfMigrationContext.Provider>
  );
};
