import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useState,
} from "react";
import { useRouteMatch } from "react-router";
import { KfMigrationContext } from "./KfMigrationContext";
import {
  Retry,
  Status as RetryStatus,
} from "../../../../../../components/retry/Retry";
import {
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Status,
} from "@lysaab/ui-2";
import { BankIDLogo } from "@lysaab/ui-2/components/bankid-token/BankIDLogo";
import { openBankidApp } from "@lysaab/ui-2/components/bankid-token/DeviceDetection";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { dataDanica } from "../../../../../../data/dataDanica";

interface Params {
  signingId: string;
  userId: string;
}

const POLL_TIMER = 3000;

interface Props {
  navigateToSigning: (signingId: string) => void;
}

export const Sign: React.VoidFunctionComponent<Props> = ({
  navigateToSigning,
}) => {
  const started = useRef<boolean>(false);
  const { params } = useRouteMatch<Params>();
  const { signingId, userId } = params;
  const [response, setResponse] = useState<Status>(Status.PENDING);
  const { state: kfWithdrawalState, setState: setKfWithdrawalState } =
    useContext(KfMigrationContext);
  const [retryStatus, setRetryStatus] = useState<RetryStatus>(
    RetryStatus.PENDING
  );
  const isActive = useRef(true);

  const poll = useCallback(
    (signingId: string, transactionId: string) => {
      if (!isActive.current) {
        return;
      }
      dataDanica
        .kfMigrationPoll(signingId, transactionId)
        .then((data) => {
          const { status } = data;
          if (status === Status.PENDING) {
            setTimeout(() => {
              poll(signingId, transactionId);
            }, POLL_TIMER);
          } else if (status === Status.COMPLETE) {
            setKfWithdrawalState({
              signResponse: undefined,
            });
            navigateToSigning(signingId);
            // history.push(
            //   getNavLink(ROUTES.SIGNERS.replace(":signingId", signingId))
            // );
          } else {
            setResponse(status);
          }
        })
        .catch(() => {
          setResponse(Status.FAILED);
        });
    },
    [setKfWithdrawalState, navigateToSigning]
  );

  const init = useCallback((setKfWithdrawalState, poll, signingId, userId) => {
    dataDanica
      .kfMigrationSign(signingId, userId)
      .then((data) => {
        setKfWithdrawalState({
          signResponse: data,
        });
        setTimeout(() => {
          poll(signingId, data.transactionId);
        }, POLL_TIMER);
        setRetryStatus(RetryStatus.SUCCESS);
      })
      .catch((err) => {
        setRetryStatus(RetryStatus.ERROR);
      });
  }, []);

  useEffect(() => {
    if (started.current) {
      return;
    }
    started.current = true;
    init(setKfWithdrawalState, poll, signingId, userId);
  }, [signingId, userId, setKfWithdrawalState, poll, init]);

  useEffect(() => {
    return () => {
      isActive.current = false;
    };
  }, []);

  const autostarttoken = kfWithdrawalState.signResponse?.autostartToken ?? "";

  return (
    <div className="withdrawal-request-page-kf-sign">
      <Retry
        status={retryStatus}
        retry={() => {
          setRetryStatus(RetryStatus.PENDING);
          setTimeout(() => {
            init(setKfWithdrawalState, poll, signingId, userId);
          }, 800);
        }}
      >
        {response === Status.PENDING && (
          <>
            <Spinner />
            <p>
              <TranslatedText
                id="withdrawalRequest.kf.sign.header"
                defaultMessage="Waiting on confirmation..."
                description="withdrawal request kf - sign header"
              />
            </p>
            <div className="bankid-logo">
              <BankIDLogo size={80} />
            </div>
            <div>
              <TranslatedText
                id="withdrawalRequest.kf.sign.ingress"
                defaultMessage="Open BankID in order to sign"
                description="withdrawal request kf - sign ingress"
              />
            </div>

            <div className="bankid-button">
              <Button
                onClick={() => openBankidApp(autostarttoken)}
                label={<TranslatedText id="withdrawalRequest.kf.sign.open" />}
              />
            </div>
          </>
        )}

        {response === Status.FAILED && (
          <>
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <div>
                <TranslatedText
                  id="withdrawalRequest.kf.sign.error"
                  defaultMessage="<bold>Ouch!</bold> Something when wrong"
                  description="withdrawal request kf - sign error"
                />
              </div>
            </Snackbar>

            <Button
              onClick={() => {
                setResponse(Status.PENDING);
                init(setKfWithdrawalState, poll, signingId, userId);
              }}
              label={<TranslatedText id="withdrawalRequest.kf.sign.retry" />}
            />

            <div className="return-button">
              <Button
                variant="secondary"
                onClick={() => {
                  // history.push(
                  //   getNavLink(ROUTES.SIGNERS.replace(":signingId", signingId))
                  // );
                  navigateToSigning(signingId);
                }}
                label={<TranslatedText id="withdrawalRequest.kf.sign.back" />}
              />
            </div>
          </>
        )}
      </Retry>
    </div>
  );
};
