import React, { useState, useEffect } from "react";
import {
  ExpandableCard,
  VisibleContent,
  HiddenContent,
  useDebounceValue,
  InvestmentType,
} from "@lysaab/ui-2";
import { Amount } from "../../../../../../components/amount/Amount";
import {
  MAX_ALLOCATION_INVESTMENT,
  MIN_ALLOCATION_INVESTMENT,
} from "./EditAllocation";
import { dataFees, FeesEstimated, Tax } from "../../../../../../data/dataFees";
import { Change } from "../../../../../../components/amount/Change";
import { AccountType } from "../../../../../../data/dataAccounts";
import { LysaCountry } from "@lysaab/countries";

interface Props {
  risk: number;
  investment: string;
  monthly: string;
  investmentType: InvestmentType;
}

const DEBOUNCE_TIMER = 500;

export const EditAllocationFees: React.FC<Props> = ({
  risk,
  investment,
  monthly,
  investmentType,
}) => {
  const [fees, setFees] = useState<FeesEstimated>();
  const [yearlyTax, setYearlyTax] = useState<Tax>();
  const [monthlyTax, setMonthlyTax] = useState<Tax>();
  const debouncedRisk = useDebounceValue(risk, DEBOUNCE_TIMER);

  useEffect(() => {
    const amount = parseInt(investment || "10000", 10);

    if (
      amount < MIN_ALLOCATION_INVESTMENT ||
      amount > MAX_ALLOCATION_INVESTMENT
    ) {
      setFees(undefined);
      return;
    }

    dataFees
      .getEstimatedFeesSignedIn({
        amount,
        risk: debouncedRisk,
        investmentType: investmentType,
        country: LysaCountry.SWEDEN,
        accountType: AccountType.DANICA_KF,
      })
      .then((fees) => {
        setFees(fees);
      })
      .catch(() => {
        setFees(undefined);
      });
  }, [debouncedRisk, investmentType, investment]);

  useEffect(() => {
    const amount = parseInt(investment || "10000", 10);

    if (
      amount < MIN_ALLOCATION_INVESTMENT ||
      amount > MAX_ALLOCATION_INVESTMENT
    ) {
      setYearlyTax(undefined);
      return;
    }

    dataFees
      .getEstimatedTaxSingleDeposit(investment)
      .then((data) => setYearlyTax(data))
      .catch(() => setYearlyTax(undefined));
  }, [investment]);

  useEffect(() => {
    if (!monthly) {
      setMonthlyTax(undefined);
      return;
    }

    dataFees
      .getEstimatedTaxMonthlyDeposit(monthly)
      .then((data) => setMonthlyTax(data))
      .catch(() => setMonthlyTax(undefined));
  }, [monthly]);

  let lysaCost;
  let lysaPercentage;
  let percentageTotal;
  let fundsPercentage;
  let fundsCost;
  let transactionsPercentage;
  let transactionsCost;
  let insurancePercentage;
  let insuranceCost;
  let totalCost;
  let amount;
  if (fees) {
    lysaPercentage = (
      <Change
        change={fees.cost.discretionary + fees.cost.fundManagement}
        digits={3}
      />
    );
    lysaCost = (
      <Amount amount={fees.future.discretionary + fees.future.fundManagement} />
    );
    fundsPercentage = <Change change={fees.cost.fundAssets} digits={3} />;
    fundsCost = <Amount amount={fees.future.fundAssets} />;
    transactionsPercentage = (
      <Change change={fees.cost.transactionFees} digits={3} />
    );
    transactionsCost = <Amount amount={fees.future.transactionFees} />;
    insurancePercentage = (
      <Change change={fees.cost.insurancePremium || 0} digits={3} />
    );
    insuranceCost = <Amount amount={fees.future.insurancePremium || 0} />;
    totalCost = <Amount amount={fees.future.total} />;
    percentageTotal = <Change change={fees.cost.total} digits={3} />;

    amount = <Amount amount={fees.future.total} />;
  } else {
    lysaCost = <span>-</span>;
    lysaPercentage = <span>-</span>;
    percentageTotal = <span>-</span>;
    fundsPercentage = <span>-</span>;
    fundsCost = <span>-</span>;
    transactionsPercentage = <span>-</span>;
    transactionsCost = <span>-</span>;
    insurancePercentage = <span>-</span>;
    insuranceCost = <span>-</span>;
    totalCost = <span>-</span>;
    amount = <div>-</div>;
  }

  return (
    <div className="create-account-corporation-fees">
      <ExpandableCard
        buttonOpenText="Visa detaljer"
        buttonCloseText="Dölj avgifter"
      >
        <VisibleContent>
          <div className="create-account-corporation-fees-top">
            <h4>Uppskattad årsavgift</h4>
            {amount}
          </div>
        </VisibleContent>
        <HiddenContent>
          <ul>
            <li>
              <div>Lysa - {lysaPercentage}</div>
              <div>{lysaCost}</div>
            </li>
            <li>
              <div>Fonder - {fundsPercentage}</div>
              <div>{fundsCost}</div>
            </li>
            <li>
              <div>Transaktionsavgifter - {transactionsPercentage}</div>
              <div>{transactionsCost}</div>
            </li>
            <li>
              <div>Försäkringsavgift - {insurancePercentage}</div>
              <div>{insuranceCost}</div>
            </li>
            <li className="total-row">
              <div>Totalt - {percentageTotal}</div>
              <div>{totalCost}</div>
            </li>
          </ul>

          <div className="create-account-corporation-tax">
            {yearlyTax ? (
              <>
                <h4>Skatt</h4>
                Kapitalförsäkringen drar av den schablonskatt som ska betalas
                automatiskt. Skattesatsen är för närvarande (för år 2020){" "}
                <span className="kf-tax">
                  <Change change={yearlyTax.taxPercentage} digits={3} />.
                </span>
                <br />
                <br />
                <div>
                  Baserat på en engångsinsättning om{" "}
                  <Amount amount={parseInt(investment, 10)} /> betalar du{" "}
                  <Amount amount={yearlyTax.taxAmount} /> i årlig skatt (det
                  första hela året)
                </div>
              </>
            ) : null}

            {monthlyTax ? (
              <div className="create-account-corporation-tax-monthly">
                <div>
                  Baserat på en månatlig insättning om{" "}
                  <Amount amount={parseInt(monthly, 10)} /> betalar du{" "}
                  <Amount amount={monthlyTax.taxAmount} /> i skatt (det första
                  hela året).
                </div>
              </div>
            ) : null}
          </div>
          <p>
            Den uppskattade årsavgiften baseras på din valda målfördelning och
            ditt nuvarande investerade belopp. Framtida skattebetalning är
            beroende av storleken på ditt investerade kapital samt i viss mån
            när inbetalningar görs till din försäkring. Kostnader och skatt på
            din investering innebär att avkastningen minskar med motsvarande
            belopp.
          </p>
        </HiddenContent>
      </ExpandableCard>
    </div>
  );
};
