import { Button, Card, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { KF_WITHDRAWAL_STATUS } from "../../../../../../../data/dataDanica";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { WITHDRAWAL_PAGE_URL } from "../../../../../../../pages/withdrawal/overview/WithdrawalPage";
import { KfMigrationContext } from "../KfMigrationContext";

export const PendingDanicaManualVerification: React.FunctionComponent = () => {
  const { state: kfWithdrawalState } = useContext(KfMigrationContext);

  if (
    kfWithdrawalState.signingStatus?.status !==
    KF_WITHDRAWAL_STATUS.PENDING_DANICA_MANUAL_VERIFICATION
  ) {
    return null;
  }

  return (
    <Card>
      <h4>
        <TranslatedText
          id="withdrawalRequest.kf.signing.manual.header"
          description="withdrawal request kf - status manual header"
          defaultMessage="Manual examination"
        />
      </h4>

      <Snackbar type={SNACKBAR_TYPES.WARNING}>
        <div>
          <TranslatedText
            id="withdrawalRequest.kf.signing.manual.paragraph"
            description="withdrawal request kf - status manual paragraph"
            defaultMessage="Futur Pension must examine this withdrawal manually. We'll send you an email when the withdrawal is ready to sign."
          />
        </div>
      </Snackbar>

      <Button
        component={Link}
        to={getNavLink(WITHDRAWAL_PAGE_URL)}
        block
        label={
          <TranslatedText id="withdrawalRequest.kf.signing.manual.button" />
        }
      />
    </Card>
  );
};
