import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Card,
  Button,
  InvestmentType,
  AllocationBarIntl,
  RiskIndicator,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
  Spinner,
} from "@lysaab/ui-2";
import {
  defaultKFState,
  KFAccountContext,
  MIN_RECOMMENDED_ALLOCATION,
} from "../KFAccountContext";
import { Disclaimer } from "../Disclaimer";
import "./Advise.scss";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  GridRow,
  JustifyContentValues,
} from "../../../../../../components/grid/gridRow/GridRow";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import {
  dataCreateAccount,
  isValidSuitabilityAssessmentRequest,
  SuitabilityAssessmentRequest,
} from "../../../../../../data/dataCreateAccount";
import {
  EsgResultResponse,
  getAccountQuestions,
  isValidAccountQuestions,
} from "../../../../../../data/dataInvestments";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import { Link } from "react-router-dom";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "../CreateAccountCorporationPage";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { SuitabilityDownload } from "../../../../../../pageComponents/advise/SuitabilityDownload";

export const ROUTE = "/advice";

interface Props {
  editAllocationRoute: string;
  candidateRoute: string;
}

interface MessageWithId {
  id: string;
}

const investmentTypeMessages = defineMessages<
  InvestmentType,
  MessageWithId,
  Record<InvestmentType, MessageWithId>
>({
  [InvestmentType.BROAD]: {
    id: "sweden.kf.advice.card.investmenttype.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "sweden.kf.advice.card.investmenttype.SUSTAINABLE",
  },
});

export const Advise: React.FC<Props> = ({
  candidateRoute,
  editAllocationRoute,
}) => {
  const kfContext = useContext(KFAccountContext);
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [esgResult, setEsgResult] = useState<EsgResultResponse["esgResult"]>();
  const stocks = intl.formatNumber(kfContext.state.advicedRisk || 0, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  const bonds = intl.formatNumber(100 - (kfContext.state.advicedRisk || 0), {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    if (!loading) {
      return;
    }

    const data: Partial<SuitabilityAssessmentRequest> = {
      language: localizationContext.state.language,
      ...getAccountQuestions(kfContext.state),
    };

    if (!isValidSuitabilityAssessmentRequest(data)) {
      setLoading(false);
      throw new Error(
        "kf account - Advice - not valid data in isValidAccountQuestions"
      );
    }

    dataCreateAccount
      .getKFSuitabilityAssessment(data)
      .then((response) => {
        setLoading(false);
        setEsgResult(response.esgResult);
        kfContext.setState({
          advicedRisk: response.advisedRisk,
          takenRisk: response.advisedRisk,
          declaration: response.declaration,
          allocationSelectedRisk: response.advisedRisk,
          investmentType: response.investmentType,
        });
      })
      .catch(() => setLoading(false));
  }, [kfContext, loading, localizationContext.state.language]);

  if (loading || !kfContext.state.investmentType) {
    return <Spinner />;
  }

  return (
    <div className="create-account-corporation-advice">
      <h2>
        <TranslatedText
          id="sweden.kf.advice.header"
          defaultMessage="Advice"
          description="create kf account - advice header"
        />
      </h2>
      <section className="allocation-proposal">
        <h3>
          <TranslatedText id="sweden.kf.advice.target" />
        </h3>
        <AllocationBarIntl
          messages={{
            stocks: (
              <TranslatedText
                id="sweden.kf.advice.stocks"
                values={{
                  stocks,
                }}
              />
            ),
            bonds: (
              <TranslatedText
                id="sweden.kf.advice.bonds"
                values={{
                  bonds,
                }}
              />
            ),
          }}
          risk={kfContext.state.advicedRisk || 0}
        />
        <GridRow justifyContent={JustifyContentValues.SPACE_BETWEEN}>
          <GridCol disabledFlexGrow>
            <Typography type="label-large">
              <FormattedMessage id="advice.card.investmenttype.title" />
            </Typography>
          </GridCol>
          <GridCol
            disabledFlexGrow
            className="create-account-corporation-advice-indicator"
          >
            {intl.formatMessage(
              investmentTypeMessages[kfContext.state.investmentType]
            )}{" "}
            <RiskIndicator
              risk={kfContext.state.advicedRisk || 0}
              investmentType={kfContext.state.investmentType}
            />
          </GridCol>
        </GridRow>
        <div className="allocation-proposal-investment-type">
          {kfContext.state.investmentType === InvestmentType.BROAD ? (
            <div>
              <TranslatedText
                id="sweden.kf.advice.broad"
                values={{
                  nbr: (text: string) => {
                    return intl
                      .formatNumber(Number.parseInt(text, 10))
                      .replace(/ /g, "&nbsp;");
                  },
                }}
              />
            </div>
          ) : (
            <div>
              <TranslatedText
                id="sweden.kf.advice.sustainable"
                values={{
                  nbr: (text: string) => {
                    return intl
                      .formatNumber(Number.parseInt(text, 10))
                      .replace(/ /g, "&nbsp;");
                  },
                }}
              />
            </div>
          )}
        </div>
      </section>

      {(kfContext.state?.advicedRisk ?? 0) < MIN_RECOMMENDED_ALLOCATION ? (
        <section>
          <Snackbar icon={true} type={SNACKBAR_TYPES.WARNING}>
            <div>
              <TranslatedText
                id="sweden.kf.advice.warning"
                values={{
                  MIN_RECOMMENDED_ALLOCATION,
                }}
              />
              <br />
              <br />
              <b>
                <Link
                  to={getNavLink(CREATE_ACCOUNT_CORPORATION_PAGE_URL)}
                  onClick={() => {
                    kfContext.setState(defaultKFState);
                  }}
                >
                  <TranslatedText
                    id="sweden.kf.advice.openVp"
                    defaultMessage="Open VP account"
                    description="create kf account - advice open vp account button"
                  />
                </Link>
              </b>
            </div>
          </Snackbar>
        </section>
      ) : null}

      <section>
        <Button
          onClick={() => {
            kfContext.setState({ takenRisk: kfContext.state.advicedRisk });
            history.push(candidateRoute);
          }}
          block
          data-test-id="advice-next"
          label={<TranslatedText id="sweden.kf.advice.next" />}
        />

        <Button
          variant="secondary"
          block
          type="button"
          onClick={() => history.push(editAllocationRoute)}
          label={<TranslatedText id="sweden.kf.advice.allocation" />}
        />

        <Button
          variant="secondary"
          block
          type="button"
          onClick={() => history.push(editAllocationRoute)}
          label={<TranslatedText id="sweden.kf.advice.fees" />}
        />
      </section>
      <section>
        <Card className="box-margin">
          <h4>
            <TranslatedText id="sweden.kf.advice.explanation" />
          </h4>
          <p>{kfContext.state.declaration}</p>
        </Card>
      </section>
      {isValidAccountQuestions(kfContext.state) &&
        esgResult &&
        typeof kfContext.state.advicedRisk !== "undefined" && (
          <SuitabilityDownload
            esgQuestions={esgResult}
            accountQuestions={getAccountQuestions(kfContext.state)}
            advisedRisk={kfContext.state.advicedRisk}
            isKf
          />
        )}
      <div className="box-margin">
        <Disclaimer />
      </div>
    </div>
  );
};
