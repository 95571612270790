import React, { useState, useEffect, useCallback } from "react";
import { Page } from "../Page";
import { GraphCard } from "./GraphCard";
import { OverviewPageAccounts } from "./OverviewPageAccounts";
import { Retry, Status } from "../../components/retry/Retry";
import { dataAccounts } from "../../data/dataAccounts";
import "./OverviewPage.scss";
import { useAccounts } from "../../hooks/useAccounts";
import { WaitingSharedAccountInvitations } from "./WaitingSharedAccountInvitations";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { getNavLink } from "../../hooks/useCountryUrls";
import { SHARE_ACCOUNT_INVITATIONS_URL } from "../shareAccountInvitations/ShareAccountInvitationsPage";
import { NewsBanner } from "./components/newsBanner/NewsBanner";
import { KfMigrationInfo } from "./components/components/KfMigrationInfo";
import { KfActiveMigrationInfo } from "./components/components/KfActiveMigrationInfo";

export const OVERVIEW_PAGE_URL = "/";

export function OverviewPage() {
  const { accounts, getAccounts } = useAccounts();
  const [status, setStatus] = useState<Status>(Status.PENDING);

  const load = useCallback(() => {
    getAccounts()
      .then(() => {
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [getAccounts]);

  const retry = useCallback(() => {
    dataAccounts.clearGetAccounts();
    setTimeout(load, 500);
  }, [load]);

  useEffect(load, [load]);

  return (
    <Page className="overview-page">
      <NewsBanner />
      <Retry retry={retry} status={status}>
        <GraphCard
          accounts={
            typeof accounts !== "undefined"
              ? [...accounts.investmentAccounts, ...accounts.savingsAccounts]
              : []
          }
        />
        <RouteAwareToggle path={getNavLink(SHARE_ACCOUNT_INVITATIONS_URL)}>
          <WaitingSharedAccountInvitations />
        </RouteAwareToggle>
        <KfMigrationInfo />
        <KfActiveMigrationInfo />
        <OverviewPageAccounts accounts={accounts} />
      </Retry>
    </Page>
  );
}
