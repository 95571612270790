import { Page } from "../../../../../../pages/Page";
import {
  GridRow,
  JustifyContentValues,
} from "../../../../../../components/grid/gridRow/GridRow";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { Link } from "react-router-dom";
import { MIGRATION_REQUEST_KF_EXTENDED_PAGE_URL } from "../request/MigrationKfRequestPageExtended";

export const MIGRATION_KF_INFO_SHARES_PAGE_URL = "/migration/kf/info-shares";

export const MigrationInfoShares = () => {
  return (
    <Page>
      <GridRow justifyContent={JustifyContentValues.CENTER}>
        <GridCol xsmall={12} medium={6}>
          <h2>
            Kapitalförsäkring i egen regi - möjlighet att byta från Futur
            Pension
          </h2>
          <p>
            Nu kan Lysa erbjuda försäkringssparande från vårt eget
            försäkringsbolag - Lysa Life Försäkring AB (Lysa Life). Först ut är
            Lysas företagsägda kapitalförsäkring!
          </p>
          <p>
            Med ett eget försäkringsbolag kan vi på ett mer effektivt sätt både
            möta våra kunders efterfrågan om nya produkter och göra
            förbättringar i de produkter som vi redan erbjuder. Som företagare
            har du kunnat spara med Lysa i en kapitalförsäkring sedan 2020 via
            vår externa samarbetspartner Futur Pension.
          </p>
          <p>
            Från och med januari 2024 kommer Lysa, via det egna
            försäkringsbolaget Lysa Life, istället kunna erbjuda
            kapitalförsäkring i egen regi. Företagaren tecknar produkten i
            inloggat läge på lysa.se i ett enkelt och digitaliserat flöde.
          </p>
          <p>
            Försäkringsavgiften är 0,2 % av det förvaltade kapitalet, vilket är
            samma kostnad som när vi samarbetade med Futur.{" "}
          </p>
          <h4>
            Vi erbjuder befintliga kunder att “flytta” sin försäkring till Lysa
            Life över årsskiftet - och våra större kunder möjligheten att flytta
            fondandelarna (via en värdepappersdepå) för att möjliggöra ett byte
            av försäkringsbolag utan att lämna marknaden.
          </h4>
          <p>
            På grund av hur skatten tas ut ur kapitalförsäkringar är det
            fördelaktigt att byta försäkringsbolag över ett årsskifte. Lysa har
            tagit fram en enkel metod för dig som vill byta försäkringsbolag
            till Lysa Life där vi tar hand om hela processen och du skriver
            under med BankID.
          </p>
          <p>
            Istället för att göra ett normalt återköp - alltså att sälja dina
            fondandelar i den gamla försäkringen för att sedan köpa tillbaka dem
            till den nya (vilket skulle innebära att pengarna låg utanför
            marknaden ett antal handelsdagar) erbjuder vi våra större kunder att
            flytta fondandelarna till en tillfällig värdepappersdepå där de
            kommer förvaras över årsskiftet. Det innebär att företaget är fullt
            investerat när flytten mellan försäkringsbolagen genomförs.
          </p>
          <p>
            Åtgärden innebär att företaget behöver betala en schablonskatt
            eftersom företaget äger fondandelar vid årets ingång. Skatten uppgår
            till 0,0824% (exempelvis 8 240 SEK vid en investering om tio
            miljoner SEK). Företaget behöver även i bokföringen ange att
            pengarna fanns placerade i fondandelar vid årets ingång. Lysa
            tillhandahåller ett underlag till bokföringen i inloggat läge på
            lysa.se eller via mail om ett sådant skulle efterfrågas.
          </p>
          <p>
            Som ersättning för den schablonskatt som företaget behöver betala
            kommer de som genomför flytten nu att få hela försäkringsavgiften
            rabatterade för hela år 2024 -{" "}
            <strong>
              försäkringen kommer alltså att vara kostnadsfri hela nästa år.
            </strong>
          </p>
          <p>
            I samband med årsskiftet 2024/2025 upphör samarbetet med Futur och
            försäkringar som då inte har flyttats till Lysa Life kommer då att
            avslutas och pengarna kommer att betalas ut till företagets
            bankkonto
          </p>
          <Link to={getNavLink(MIGRATION_REQUEST_KF_EXTENDED_PAGE_URL)}>
            Tillbaka till flyttanmälan
          </Link>
        </GridCol>
      </GridRow>
    </Page>
  );
};
