import React, { FunctionComponent, useContext } from "react";
import { Button, Card, Icon, SNACKBAR_TYPES, Snackbar } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import "./Intro.scss";
import { UserContext } from "../../../../../../context/UserContext";
import { Link, Redirect } from "react-router-dom";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_URL } from "../../../../../../pages/createAccount/CreateAccountStory";
import { LOGIN_SWEDEN_PAGE_URL } from "../../../LoginPage";

interface Props {
  exitRoute: string;
  lysaKycRoute: string;
  lysaSummaryRoute: string;
  futurSummaryRoute: string;
}

export const Intro: FunctionComponent<Props> = ({
  lysaKycRoute,
  exitRoute,
  lysaSummaryRoute,
  futurSummaryRoute,
}) => {
  const userContext = useContext(UserContext);
  const user = userContext.state;

  if (!user.name) {
    return <Redirect to={LOGIN_SWEDEN_PAGE_URL} />;
  }

  return (
    <div className="create-account-corporation-intro">
      <h1>
        <TranslatedText id="sweden.kf.new" />
      </h1>
      <div className="intro-ingress">
        <Icon.Checkmark />
        <TranslatedText id="sweden.kf.ingress" />
      </div>

      <Card>
        <h2>Kapitalförsäkring</h2>
        <Snackbar type={SNACKBAR_TYPES.INFO}>
          Vi arbetar just nu på att ta fram ett eget, bättre
          kapitalförsäkringsalternativ. Vi hoppas kunna erbjuda detta försäkring
          f.r.o.m. <strong>2024-01-02</strong>. Fram till dess går det tyvärr
          inte att teckna en kapitalförsäkring hos oss.
        </Snackbar>
      </Card>

      <Card>
        <h2>
          <TranslatedText id="sweden.vp" />
        </h2>
        <p>
          <TranslatedText id="sweden.vp.ingress" />
        </p>
        <div className="bottom-nav">
          <Button
            component={Link}
            block
            to={getNavLink(CREATE_ACCOUNT_URL)}
            label={<TranslatedText id="sweden.vp.link" />}
          />
        </div>
      </Card>
    </div>
  );
};
